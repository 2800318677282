// Colors
$red: #AB2C23;
$red-dark: #832119;
$red-light: #ac504a;
$yellow: #F9C339;
$yellow-dark: #DCA310;

// Overwrite font sizes
h4 { font-size: 1.25rem; }
h5 { font-size: 1rem; }
h6 { font-size: 0.875rem; }
p  { font-size: 0.75rem; }

// Overwrite buttons
.btn {
    font-size: 0.75rem;
    padding: 0.6rem 1.5rem;
    background: $red;
    border-radius: 0%;
    border: none;
}

.btn:hover { background: $red-dark; }

// Classes
.img-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;

    img {
        display: block;
        margin: auto;
        max-width: 100%;
        max-height: 90%;
    }
}

// Loading Spinner
#spinner-container {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .spinner-border { color: $red; }
}

// Sections
header {
    position: absolute;
    width: 100%;
    z-index: 10;
    background-color: rgba($color: white, $alpha: 0.3);

    .navbar {
        width: 767px;
        margin: auto;
        .container-fluid { padding: 0; }
    }
    .nav-item {
        font-size: 1rem;
        i { color: $red; }
        span { font-weight: 600; }
    }
    @media (max-width: 850px) {
        .navbar { width: 90%; }
    }
}

#headline {
    #headline-left {
        display: flex;
        align-items: center;

        #headline-content {
            width: 550px;
            margin-top: 60px;
            margin-left: auto;
            padding-left: 5%;
            padding-right: 20px;

            #headline-text {
                text-align: justify;
                #headline-product-title { 
                    text-transform: uppercase;
                    font-weight: bold;
                }
                #headline-product-des {
                    font-size: 0.65rem;
                }
                .btn-sm { width: 100%; } 
            }
    
            .headline-btns {
                width: fit-content;
                font-size: 0.7rem;
                i { color: lightgray; }
                .active { color: $red;}
            }
            .headline-btns:hover { cursor: pointer; }
            .headline-btns * { pointer-events: none; }
        }
    }

    #headline-right {
        #headline-bg {
            background-image: linear-gradient(rgba($red, 0.3), rgba($red, 0.3)),
                        url("../public/images/frames/bg-headline.jpg");
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            box-shadow: none;
            border: none;
            filter: blur(3px);
            height: 470px;
        }

        #headline-form {
            position: absolute;
            top: 0;
            padding-top: 90px;
            padding-left: 20px;
            width: 250px;
            color: white;
            
            form {
                label { margin: 0; }

                .col-12 > div { margin-bottom: 0.3rem !important; }

                .btn { margin-top: 1rem; }
            }
            

        }
    }
}

@media (max-width: 767px) {
    header { position: relative; }

    #headline {
        height: fit-content !important;

        #headline-left {
            margin-top: 2rem;
            margin-bottom: 2rem;
        }

        #headline-content {
            width: 90% !important;
            margin-left: auto !important;
            margin-right: auto !important;
            margin-top: 0 !important;   
        }

        #headline-right { display: none; }
    }
}

form {
    .form-label, .form-control { font-size: 0.8rem; }
    .form-control { border-radius: 0;}

    .btn {
        width: 100%;
        background: $yellow;
        color: black;
    }

    .btn:hover {
        background: $yellow-dark;
    }

    .formProductInput { text-transform: uppercase; }
    
    p { color: $red; }

    .invalid-feedback { font-size: 0.7rem; }
}

#about {
    #about-left {
        #about-bg {
            background-image: linear-gradient(to right, rgba($color: $red-light, $alpha: 0.2) 0%, rgba($color: $red, $alpha: 1) 100%),
                                url("../public/images/frames/bg-about.png");
            background-position: center;
            background-size: cover;
            height: 100%;
        }
    }

    #about-right {
        background-color: $red;
        color: white;
        
        #about-content { width: 540px; }

        @media (max-width: 880px) {
            #about-content { width: 90%; }
        }
        @media (max-width: 767px) {
            #about-content { margin: auto; }
        }
    }
}

#products {
    width: 767px;
    margin: auto;

    .btn-icon {
        width: fit-content !important;
        padding: 0.4rem 0.6rem;
        margin: 0.2rem;
        font-size: 0.6rem;
        background-color: $red;
        color: white;
        -webkit-text-stroke: 1px;
    }
    
    .col * { pointer-events: none; }

    h6 { text-transform: uppercase; }

    p { text-align: justify; }

    .react-read-more-read-less {
        display: block;
        color: $red;
        text-decoration: underline;
        margin-top: 0.5rem;
        pointer-events: all;
    }

    .btn { 
        width: 100%;
        pointer-events: all;
    }

    img {
        max-width: 80%;
        max-height: 80%;
    }
}

@media (max-width: 850px) {
    #products { width: 90%; }
}

#promo {
    #promo-advantages {
        background-color: $red;
        padding-top: 3rem;
        padding-bottom: 3rem;
    }

    #promo-counterdown {
        background-image: linear-gradient(to right, rgba($color: $red, $alpha: 1) 0%, rgba($color: $red-dark, $alpha: 0.5) 100%),
                            url("../public/images/frames/bg-promo.png");
        background-position: center;
        background-size: cover;
        padding-top: 3rem;
        padding-bottom: 3rem;
        display: flex;
        align-items: center;

        #counterdown {
            text-align: center;
            height: fit-content;
            width: 375px;
            margin-right: auto;
            padding: 2rem;
            background-color: $yellow;

            span {
                font-size: 1.5rem;
                font-weight: 700;
            }
        }
    }
}

#advantages {
    color: white;
    width: 375px;
    margin-left: auto;

    .advantage {
        display: flex;
        margin-top: 1rem;

        .advantage-text {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
        i { 
            font-size: 2.5rem;
            color: $yellow-dark;
        }
        p { margin: 0;}
    }
}

@media (max-width: 860px) {
    #counterdown { width: 90% !important; }
    #advantages { width: 90%; }
}

@media (max-width: 767px) {
    #counterdown {
        margin-left: auto;
        padding: 0 !important;
        background-color: transparent !important;
    }
    #advantages { margin-right: auto; }
}

@media (min-width: 576px) and (max-width: 767px) {
    #promo {
        #promo-counterdown {
            background-image: linear-gradient(to right, rgba($color: $yellow, $alpha: 1) 10%, rgba($color: $yellow-dark, $alpha: 0.5) 100%),
                            url("../public/images/frames/bg-promo.png");

            #counterdown {
                text-align: start;
                width: 90%;
            }
        }
    }
}

@media (max-width: 575px) {
    #promo {
        #promo-advantages {
            padding-top: 2rem;
            padding-bottom: 2rem;
        }
        
        #promo-counterdown {
            background-image: none  ;
            background-color: #F9C339;
            padding-top: 2rem;
            padding-bottom: 2rem;
        }
    }
}

#scopes {
    width: 767px;
    margin: auto;

    .row { margin: 0; }

    .col {
        padding: 0;
        img { max-height: 100%; }
    }

    .btn-icon {
        width: fit-content !important;
        padding: 0.4rem 0.6rem;
        margin: 0.2rem;
        font-size: 0.6rem;
        background-color: $red;
        color: white;
        -webkit-text-stroke: 1px;
    }
}

@media (max-width: 850px) {
    #scopes { width: 90%; }
}

#stages {
    background-color: #F0F4F3;
    height: fit-content;

    #stages-content {
        width: 767px;
        margin: auto;

        .stage {
            text-align: center;

            .stage-number {
                color: white;
                margin-bottom: 0;
                font-size: 10rem;
                font-weight: 800;
            }
            .stage-text {
                font-size: 0.9rem;
                font-weight: 700;
                margin-top: -8rem;
            }
        }
    }
}

@media (max-width: 850px) {
    #stages > #stages-content { width: 90%; }
}

@media (max-width: 575px) {
    #stages {
        padding-top: 1rem;
        padding-bottom: 1rem;

        .stage {
            display: flex;
            justify-content: center;
    
            .row {
                width: 100%;
            }
    
            .stage-number {
                font-size: 5rem !important;
            }
            .stage-text { 
                display: flex;
                align-items: center;
                margin-top: 0 !important;
            }
        }
    }
}

#form-section {
    background-color: $red;
    color: white;
    padding-top: 3rem;
    padding-bottom: 3rem;

    #form-container {
        width: 767px;
        margin: auto;
        
        .col > div {
            margin-bottom: 0 !important;
        }

        .btn-form-container {
            display: flex;
            align-items: flex-end;
            margin-bottom: 0 !important;

            .btn-form { padding: 0.5rem 1.5rem !important; }
        }

        .form-required { display: none; }
    }
}

@media (max-width: 850px) {
    #form-section > #form-container { 
        width: 90%;

        .col > div { margin-bottom: 1rem !important; }

        .btn-form-container { margin-top: 1rem;}
    }
}

footer {
    background-color: #3C3C3C;
    color: white;

    #footer-content {
        width: 767px;
        margin: auto;

        #footer-logo {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
        }
        
        h5 { color: $yellow; }

        i { color: $red; }

        .img-container { height: 50px; }

        a {
            font-size: 0.875rem;        
            color: inherit;
            text-decoration: none;
        }

        a:hover { color: $red; }
    }
}

@media (max-width: 850px) {
    footer > #footer-content { width: 90%; }
}

@media (max-width: 575px) {
    #footer-logo {
        align-items: center !important;
        margin-top: 2rem;
    }
}
